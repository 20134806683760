import { Component, Input, Output } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-call-alerts-cards',
  templateUrl: './call-alerts-cards.component.html',
  styleUrls: ['./call-alerts-cards.component.scss']
})
export class CallAlertsCardsComponent {
  constructor(private cubejs: CubejsClient) {}
  priorityMap = {
    200: 'normal',
    300: 'high',
    400: 'critical'
  };

  statusMap = {
    200: 'resolved',
    100: 'unresolved' // Example status for 100
  };

  normalCount = 0;
  highCount = 0;
  criticalCount = 0;
  resolvedCount = 0;
  newCount = 0;

  @Input() set query(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = [];
        this.newCount = 0;
        this.normalCount = 0;
        this.highCount = 0;
        this.criticalCount = 0;
        this.resolvedCount = 0;
        this.dataSource = resultSet.tablePivot();
        
        this.normalCount = this.getCountByPriority(200);
        this.highCount = this.getCountByPriority(300);
        this.criticalCount = this.getCountByPriority(400);
        this.resolvedCount = this.getCountByStatus(200);

        // Calculate the total count
        this.newCount = this.normalCount + this.highCount + this.criticalCount + this.resolvedCount;
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
    console.log(this.newDataSource);
  };
  getCountByPriority(priority: number): number {
    return this.dataSource
      .filter(item => item['Tasks.priority'] === priority)
      .reduce((sum, item) => sum + item['Tasks.count'], 0);
  }

  getCountByStatus(status: number): number {
    return this.dataSource
      .filter(item => item['Tasks.status'] === status)
      .reduce((sum, item) => sum + item['Tasks.count'], 0);
  }
  @Input() set queryVehicles(queryVehicles: object) {
    this.loading = true;
    this.cubejs.load(queryVehicles).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          this.vehiclesCount = ndata['Tasks.count'];
        }
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() set queryDevice(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          this.devicesCount = ndata['Tasks.count'];
        }
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() set queryPeople(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          this.peopleCount = ndata['Tasks.count'];
        }
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = true;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  vehiclesCount = 0;
  devicesCount = 0;
  peopleCount = 0;
  newDataSource = [];
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
