<mat-grid-list [cols]="3" rowHeight="59">
    <div>
        <mat-grid-tile>
            <div class="kpi-card" style="width: 100%;padding-right: 5px;">
                <mat-card class="dashboard-card not-normal-card">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">Total</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{newCount}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
    </div>
    <div>
        <mat-grid-tile>
            <div class="kpi-card" style="width: 100%;padding-right: 5px;">
                <mat-card class="dashboard-card not-normal-card">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">New</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{newCount}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
    </div>
    <div>
        <mat-grid-tile>
            <div class="kpi-card" style="width: 100%;padding-right: 5px;">
                <mat-card class="dashboard-card not-normal-card">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">Resolved</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{resolvedCount}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
    </div>
</mat-grid-list>
<mat-grid-list [cols]="4" rowHeight="59">
    <div>
        <mat-grid-tile>
            <div class="kpi-card" style="width: 100%;padding-right: 6px;">
                <mat-card class="dashboard-card mat-card-integrity">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">Integrity</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>0</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="kpi-card" style="width: 100%;padding-right: 6px;">
                <mat-card class="dashboard-card mat-card-critical">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">Critical</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{criticalCount}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="kpi-card" style="width: 100%;padding-right: 6px;">
                <mat-card class="dashboard-card mat-card-high">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">High</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{highCount}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="kpi-card" style="width: 100%;padding-right: 6px;">
                <mat-card class="dashboard-card mat-card-normal">
                    <mat-card-header class="dashboard-card__header">
                    <mat-card-title>
                        <h3 class="kpi-title">Normal</h3>
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content kpi-result">
                    <span>{{normalCount}}</span>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
    </div>
    </mat-grid-list>
    <mat-grid-list [cols]="3" rowHeight="59">
    <mat-grid-tile>
        <div class="kpi-card" style="width: 100%;padding-right: 5px;">
            <mat-card class="dashboard-card not-normal-card">
                <mat-card-header class="dashboard-card__header">
                <mat-card-title>
                    <h3 class="kpi-title">Vehicles</h3>
                </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content kpi-result">
                <span>{{vehiclesCount}}</span>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-grid-tile>
    <mat-grid-tile>
        <div class="kpi-card" style="width: 100%;padding-right: 5px;">
            <mat-card class="dashboard-card not-normal-card">
                <mat-card-header class="dashboard-card__header">
                <mat-card-title>
                    <h3 class="kpi-title">Devices</h3>
                </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content kpi-result">
                <span>{{devicesCount}}</span>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-grid-tile>
    <mat-grid-tile>
        <div class="kpi-card" style="width: 100%;">
            <mat-card class="dashboard-card not-normal-card">
                <mat-card-header class="dashboard-card__header">
                <mat-card-title>
                    <h3 class="kpi-title">People</h3>
                </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content kpi-result">
                <span>{{peopleCount}}</span>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-grid-tile>
</mat-grid-list>